import typography from './typography';

export const strongShadows = [
  'none',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 12px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 12px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 16px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 16px -4px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 8px 18px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 9px 18px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 10px 20px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 11px 20px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 12px 22px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 13px 22px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 14px 24px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 16px 28px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 18px 30px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 20px 32px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 22px 34px -8px rgba(0,0,0,0.50)',
  '0 0 1px 0 rgba(0,0,0,0.70), 0 24px 36px -8px rgba(0,0,0,0.50)',
];

const overrides = {
  MuiButton: {
    root: {
      textTransform: 'upperCase',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '1.2em',
      minHeight: '2em',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    label: {
      minHeight: '2em',
      lineHeight: '1.2em',
    },
    fullWidth: {
      width: '100%',
      padding: '5px',
    },
    outlined: {
      border: '2px solid',
      borderRadius: '8px',
      transition: 'background 0.5s, color 0.5s',
    },
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'inherit !important',
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '8px',
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    root: {
      fontSize: 16,
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: '20px',
    },
  },
  MuiDialogActions: {
    spacing: {
      margin: '8px 24px',
    },
  },
  MuiCardHeader: {
    root: {
      padding: '16px',
    },
  },
  MuiCardContent: {
    root: {
      padding: '24px',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '8px 16px',
    },
  },
  MuiDialogTitle: {},
  MuiTableCell: {
    head: {
      fontWeight: 600,
    },
  },
  MuiDataGrid: {
    cell: {
      whiteSpace: 'normal !important',
      wordWrap: 'break-word !important'
    }
  }
};
export const darkTheme = {
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  palette: {
    mode: 'dark',
  },
  typography,
  shadows: strongShadows,
  overrides,
};

export const lightTheme = {
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  palette: {
    mode: 'light',
  },
  typography,
  shadows: strongShadows,
  overrides,

};
