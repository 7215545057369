import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { firebaseAuth } from '../config';
import { getAdminApolloClient } from '../clients/appsync';

const AppsyncProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(null);
  useEffect(() => {
    if (user) {
      getAdminApolloClient()
        .then((apolloClient) => {
          setApolloClient(apolloClient);
        });
    }
  }, [user]);

  if (error) {
    return <>{children}</>;
  }

  if (loading || !apolloClient) {
    return <>{children}</>;
  }

  return (
    <ApolloProvider client={apolloClient!}>
      {children}
    </ApolloProvider>
  );
};

export default AppsyncProvider;
