import React from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, ListSubheader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';

const sections = [
  {
    subheader: 'Users',
    items: [
      {
        title: 'User List',
        href: '/admin/users',
      },
      {
        title: 'Add User',
        href: '/admin/users/add',
      },
    ],
  },
  {
    subheader: 'Plans',
    items: [
      {
        title: 'Subscription Plans',
        href: '/admin/plans',
      },
      {
        title: 'Package Plans',
        href: '/admin/package-plans',
      },
    ],
  },
  {
    subheader: 'Reports',
    items: [
      {
        title: 'User and Esim',
        href: '/admin/reports/users',
      },
      {
        title: 'Business',
        href: '/admin/reports/business',
      },
    ],
  },

];

function renderNavItems({
  items,
  pathname,
  depth = 0,
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}) {
  const key = item.title + depth;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  if (item.items) {
    acc.push(
      // @ts-ignore
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      // @ts-ignore
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const LeftBar = () => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <div>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </Drawer>
    </div>
  );
};

LeftBar.propTypes = {};

export default LeftBar;
