import React from 'react';
import Grid from '@mui/material/Grid';
import Header from './layout/AdminUserView/Header';
import Footer from './layout/AdminUserView/Footer';

const Content = ({ children }) => (
  <Grid
    container
    direction="column"
    alignItems="stretch"
    spacing={0}
    style={{
      height: '100%',
      background: 'url("/bg-5.webp") repeat scroll 0 0',
    }}
  >
    <Grid item style={{ minHeight: '64px' }} className="gett-tv-header">
      <Header />
    </Grid>
    <Grid item container style={{ minHeight: '86vh' }} direction="column" className="gett-tv-main">
      {children}
    </Grid>
    <Grid item style={{ height: '7vh' }} className="gett-tv-footer">
      <Footer />
    </Grid>
  </Grid>
);

export default Content;
