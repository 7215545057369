import firebase from 'firebase';

const config = {
  dev: {
    firebase: {
      apiKey: 'AIzaSyCBIjizcCsoKqJzjw6gOn-dNOhlD3fUQ2M',
      authDomain: 'amie-dev-d0c8b.firebaseapp.com',
      projectId: 'amie-dev-d0c8b',
      storageBucket: 'amie-dev-d0c8b.appspot.com',
      messagingSenderId: '1049952913876',
    },
    recurlyPublicKey: 'ewr1-aJUa8eRm65ve1GgwtQmmED',
    paymentDetailsRequired: true,
    defaultPlan: 'tstm',
  },
  staging: {
    firebase: {
      apiKey: 'AIzaSyDjLYFxopNWRNOqKvtIWe9aczAr0rmWybE',
      authDomain: 'amie-staging-6029f.firebaseapp.com',
      projectId: 'amie-staging-6029f',
      storageBucket: 'amie-staging-6029f.appspot.com',
      messagingSenderId: '956407474647',
      appId: '1:956407474647:web:cbbe6c2ea76510084c4392',
    },
    recurlyPublicKey: 'ewr1-aJUa8eRm65ve1GgwtQmmED',
    paymentDetailsRequired: true,
    defaultPlan: 'tstm',
  },
  prod: {
    firebase: {
      apiKey: 'AIzaSyCQyXd-NtbqzYtxpNrNKoZDndZbXCAO3YI',
      authDomain: 'amie-prod.firebaseapp.com',
      projectId: 'amie-prod',
      storageBucket: 'amie-prod.appspot.com',
      messagingSenderId: '171748387466',
      appId: '1:171748387466:web:e13a4b9c22c8b252c69b73',
      measurementId: 'G-41LSVQ0930',

    },
    recurlyPublicKey: 'ewr1-wrMUpkvLm17VhceEL1JHEo',
    paymentDetailsRequired: true,
    defaultPlan: 'tstm',
  },
};

const env = process.env.REACT_APP_STAGE ? config[process.env.REACT_APP_STAGE] : config.dev;

const app = firebase.initializeApp(env.firebase);
export const firebaseAuth = app.auth();

export default env;
