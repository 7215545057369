import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';

const Loading = ({
  isLoading,
  text,
  children,
}) => (
  <LoadingOverlay
    active={isLoading}
    spinner
    text={text}
    styles={{
      wrapper: {
        display: 'inherit',
        'flex-grow': 'inherit',
      },
      overlay: (base) => ({
        ...base,
        background: 'rgba(0,0,0,0.1)'
      })
    }}
  >
    {children}
  </LoadingOverlay>
);

Loading.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default Loading;
