import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../config';
import LoadingScreen from './LoadingScreen';
import { listSubscriptionPlansQuery } from '../queries/admin';
import { getAdminApolloClient } from '../clients/appsync';
import { ListPlans } from '../types/admin/ListPlans';

const AdminAuthGuard = ({ children }) => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [authorized, setAuthorized] = useState<Boolean | null>(null);

  // prefetch useful admin data
  useEffect(() => {
    if (authorized) {
      getAdminApolloClient()
        .then((apolloClient) => {
          apolloClient.query<ListPlans, void>({
            query: listSubscriptionPlansQuery,
          });
        });
    }
  }, [authorized]);

  if (error) {
    return <>{error}</>;
  }

  if (!user && !loading) {
    return <Redirect to="/login" />;
  }

  user?.getIdTokenResult()
    .then((value) => {
      console.log(value.claims);
      if (value.claims?.sysadmin) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    });

  if (authorized === null) {
    return <></>;
  }

  if (!authorized) {
    return <Redirect to="/login" />;
  }

  return (
    <LoadingScreen isLoading={loading}>
      {children}
    </LoadingScreen>
  );
};

AdminAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminAuthGuard;
