import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" mt={4} alignItems="center" whiteSpace="pre-wrap" textAlign="center">
        <Typography variant="h2">
          {t('Error page title')}
          <br />
          <br />
        </Typography>
        <Typography variant="h3">
          {t('If you need assistance please')}
                    &nbsp;
          <Link target="_blank" underline="always" href="https://www.gett.tv/ru/contact-us/">
            {t('Contact us')
              .toLowerCase()}
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default ErrorPage;
