import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { firebaseAuth } from '../../../../config';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';

const JWTLogin = ({
  ...rest
}) => {
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation(['validation', 'translation']);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object()
        .shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required(t('validation:Email is required')),
          password: Yup.string()
            .max(255)
            .required(t('validation:Password is required')),
        })}
      onSubmit={async (values, {
        setStatus,
        setSubmitting,
      }) => {
        try {
          await firebaseAuth.signInWithEmailAndPassword(values.email, values.password);
          setSubmitting(false);
        } catch (err: any) {
          if (isMountedRef.current) {
            setStatus(t('Failed to login'));
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        status,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...rest}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('translation:Email')}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('translation:Password')}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography color="error">{status}</Typography>
          </Grid>
          <Box mt={2}>
            <Divider />
          </Box>
          <Box mt={3} style={{ textAlign: 'center' }}>
            <Button
              style={{ width: '50%' }}
              disabled={isSubmitting}
              type="submit"
              variant="outlined"
            >
              {t('translation:Login')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default JWTLogin;
