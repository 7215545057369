import React from 'react';
import Box from '@mui/material/Box';
import LeftBar from './LeftBar';
import Page from '../../components/Page';

const UserViewLayout = ({ children }) => (
  <Page
    title="Users"
  >
    <LeftBar />
    <Box m={4} p={8} pt={0} style={{ marginLeft: '256px' }}>
      {children}
    </Box>

  </Page>
);

UserViewLayout.propTypes = {};

export default UserViewLayout;
