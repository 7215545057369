import React from 'react';
import Loading from './Loading';

const LoadingScreen = ({
  isLoading,
  children,
}) => {
  let content;
  if (isLoading) {
    content = <Loading isLoading={isLoading} />;
  } else {
    content = (
      <>
        {children}
      </>
    );
  }
  return (content);
};

export default LoadingScreen;
