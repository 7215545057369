import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import React, { createContext, Dispatch, useContext, useState } from 'react';
import { darkTheme, lightTheme } from '../theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}

interface ContextT {
  theme: string;
  setTheme: Dispatch<string>;
  toggleTheme: Dispatch<void>;
}

const Context = createContext<ContextT>({} as ContextT);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useThemeTogglerProvider = () => useContext(Context);

export const ThemeSwitcherProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<string>('light');

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  // @ts-ignore
  return (
    <Context.Provider
      value={{
        theme,
        setTheme,
        toggleTheme,
      }}
    >
      <StyledEngineProvider injectFirst>
        {/* @ts-ignore */}
        <ThemeProvider theme={theme === 'dark' ? createTheme(adaptV4Theme(darkTheme)) : createTheme(adaptV4Theme(lightTheme))}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </Context.Provider>
  );
};
