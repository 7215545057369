import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import AdminAuthGuard from './components/AdminAuthGuard';
import Page from './components/Page';
import NotFoundView from './pages/errors/NotFoundView';
import ErrorPage from './pages/errors/ErrorPage';
import LoginView from './pages/admin/auth/LoginView';
import UserViewLayout from './layout/AdminUserView/UserViewLayout';

export const renderRoutes = (routes: any[] = []) => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || Fragment;
      const Component = route.component;
      const Layout = route.layout || Fragment;
      const render = route.render || null;
      const title = route.title || '';

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard>
              <Layout>
                <Page title={title} description={title}>
                  <Suspense fallback={<></>}>
                    {route.routes
                      ? renderRoutes(route.routes)
                      : render ? render() : <Component {...props} />}
                  </Suspense>
                </Page>
              </Layout>
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

const routes = [
  {
    path: '/404',
    exact: true,
    component: NotFoundView,
    title: 'Not found',
  },
  {
    path: '/error-page',
    exact: true,
    component: ErrorPage,
    title: 'Error page',
  },
  {
    path: '/login',
    exact: true,
    guard: GuestGuard,
    component: LoginView,
    title: 'Login',
  },
  {
    path: '/admin-profile',
    title: 'Admin Profile',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/me/AdminProfile')),
  },
  {
    path: '/',
    title: 'Admin - Users',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserListView')),
  },
  {
    path: '/admin/users',
    title: 'Admin - Users',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserListView')),
  },
  {
    path: '/admin/users/add',
    title: 'Admin - add user',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserAddView')),
  },
  {
    path: '/admin/users/:id',
    exact: true,
    title: 'Admin - view user',
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserDetailsView')),
  },
  {
    path: '/admin/users/:id/edit',
    exact: true,
    title: 'Admin - edit user',
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserDetailsEdit')),
  },
  {
    path: '/admin/users/:id/edit-payment-details',
    exact: true,
    title: 'Admin - edit user\'s payment details',
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/users/UserPaymentDetailsEdit')),
  },
  {
    path: '/admin/reports/users',
    exact: true,
    title: 'User stats',
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/reports/UserStats')),
  },
  {
    path: '/admin/reports/business',
    exact: true,
    title: 'Business stats',
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/reports/BusinessStats')),
  },
  {
    path: '/admin/plans',
    title: 'Admin - Subscription Plans',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/plans/PlansCrud')),
  },
  {
    path: '/admin/package-plans',
    title: 'Admin - Package Plans',
    exact: true,
    guard: AdminAuthGuard,
    layout: UserViewLayout,
    component: lazy(() => import('./pages/admin/plans/PackagePlansCrud')),
  }
];

export default routes;
