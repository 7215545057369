import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: theme.zIndex.modal - 1,
    top: 'auto',
    bottom: 0,
    height: '100%',
    background: theme.palette.background.default,
  },
  paper: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
}));

function Copyright() {
  return (
    <Box py={3}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'© 2021-2022 AMEI Inc. All rights reserved '}
      </Typography>
    </Box>

  );
}

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="relative" className={classes.appbar} color="transparent" elevation={0}>
        <Toolbar disableGutters style={{ justifyContent: 'center' }}>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
