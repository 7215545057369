import gql from 'graphql-tag';

export const EsimPackageFields = gql`
    fragment EsimPackage on EsimPackage {
        esimPackageId
        templateId
        locationZone
        destinationZone
        priority
        assignedDate
        activationDate
        expirationDate

        outgoingMinutes
        usedOutgoingMinutes
        usedOutgoingMinutesPercent

        outgoingSms
        usedOutgoingSms
        usedOutgoingSmsPercent

        incomingMinutes
        usedIncomingMinutes
        usedIncomingMinutesPercent

        incomingSms
        usedIncomingSms
        usedIncomingSmsPercent

        dataMb
        usedDataMb
        usedDataPercent
    }
`;

export const PackagePlanShortFields = gql`
    fragment PackagePlanShort on PackagePlan {
        id
        esimTemplateId
        recurlyId
        code
        name
        planType
        coverage
        planType
        network
        speed
        activationPolicy
        remaining
        incomingMinutes
        incomingSms
        outgoingMinutes
        outgoingSms
        isAddon
        dataMb
        phoneNumberType
        archived
    }
`;

export const PackagePlanFields = gql`
    fragment PackagePlan on PackagePlan {
        id
        esimTemplateId
        recurlyId
        code
        name
        planType
        coverage
        planType
        network
        speed
        activationPolicy
        phoneNumberType
        remaining
        incomingMinutes
        incomingSms
        outgoingMinutes
        outgoingSms
        isAddon
        dataMb
        object
        state
        amount
        description
        externalSku
        createdAt
        updatedAt
        deletedAt
    }
`;

export const RecurlySubscriptionChangeFields = gql`
    fragment RecurlySubscriptionChange on RecurlySubscriptionChange {
        planCode
        unitAmount
    }
`;

export const RecurlySubscriptionFields = gql`
    ${RecurlySubscriptionChangeFields}
    fragment RecurlySubscription on RecurlySubscription {
        id
        uuid
        state
        expiresAt
        currentPeriodStartsAt
        currentPeriodEndsAt
        trialStartedAt
        trialEndsAt
        unitAmount
        pendingChange {
            ...RecurlySubscriptionChange
        }
    }
`;

export const PackageFields = gql`
    ${EsimPackageFields}
    ${PackagePlanFields}
    fragment Package on Package {
        id
        userId
        planCode
        plan {
            ...PackagePlan
        }
        purchaseId
        subscriptionId
        type
        kind
        status
        esimSubscriberId
        esimPackageTemplateId
        esimPackageId
        esimPackage {
            ...EsimPackage
        }
    }
`;

export const SubscriptionPlanShortFields = gql`
    fragment SubscriptionPlanShort on SubscriptionPlan {
        id
        code
        name
        coverage
        activationPolicy
        esimTemplateId
        network
        planType
        recurlyId
        speed
        incomingMinutes
        incomingSms
        outgoingMinutes
        outgoingSms
        dataMb
        isPromo
        archived
        phoneNumberType
    }
`;

export const SubscriptionPlanFields = gql`
    fragment SubscriptionPlan on SubscriptionPlan {
        id
        code
        name
        coverage
        activationPolicy
        esimTemplateId
        network
        planType
        recurlyId
        speed
        incomingMinutes
        incomingSms
        outgoingMinutes
        outgoingSms
        dataMb
        phoneNumberType
        isPromo
        archived
        # recurly
        description
        object
        state
        amount
        intervalLength
        intervalUnit
        trialLength
        trialUnit
    }
`;

export const SubscriptionFields = gql`
    ${RecurlySubscriptionFields}
    ${SubscriptionPlanFields}
    ${PackageFields}
    fragment Subscription on Subscription {
        id
        userId
        planCode
        plan {
            ...SubscriptionPlan
        }
        recurlySubscription {
            ...RecurlySubscription
        }
        packages {
            ...Package
        }
    }
`;

export const BillingInfoFields = gql`
    fragment BillingInfo on BillingInfo {
        id
        firstName
        lastName
        address {
            city
            country
            postalCode
            region
            street1
        }
        paymentMethod {
            cardType
            expMonth
            expYear
            firstSix
            lastFour
        }
    }
`;

export const UserFields = gql`
    ${BillingInfoFields}
    fragment UserFields on User {
        id
        firstName
        lastName
        email
        country
        isAdmin
        recurlyStatus
        serviceStatus
        verificationStatus
        profileImageUrl
        currentPlan
        billingInfo {
            ...BillingInfo
        }
    }
`;

export const EsimFields = gql`
    fragment Esim on Esim {
        iccid
        subscriberId
        userId
        phoneNumber
        imsi
        status
        pin1
        puk1
        pin2
        puk2
        qrCode
    }
`;

export const ListUsersItemFields = gql`
    fragment ListUsersItem on User {
        id
        country
        currentPlan
        email
        isAdmin
        firstName
        lastName
        recurlyStatus
        serviceStatus
        verificationStatus
        created
        modified
    }
`;

export const EsimPlanFields = gql`
    fragment EsimPlan on EsimPlan {
        templateId
        templateName
        locationZone
        destinationZone
        outgoingMinutes
        outgoingSms
        incomingMinutes
        incomingSms
        dataMb
    }
`;

export const getMeQuery = gql`
    ${UserFields}

    query GetMe {
        getMe {
            ...UserFields
        }
    }
`;

export const getUserQuery = gql`
    ${UserFields}

    query GetUser($id: ID!) {
        getUser(id: $id) {
            ...UserFields
        }
    }
`;

export const listUsersQuery = gql`
    ${ListUsersItemFields}

    query ListUsers($limit: Int, $nextToken: String) {
        listUsers(limit: $limit, nextToken: $nextToken) {
            items {
                ...ListUsersItem
            }
            nextToken
        }
    }
`;

export const putUserMutation = gql`
    ${UserFields}

    mutation PutUser($input: PutUserInput!) {
        putUser(input: $input) {
            ...UserFields
        }
    }
`;

export const updateUserMutation = gql`
    ${UserFields}

    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            ...UserFields
        }
    }
`;

export const deleteUserMutation = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            message
            status
        }
    }
`;

export const updateSubscriptionMutation = gql`
    ${SubscriptionFields}
    mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
        updateSubscription(input: $input) {
            ...Subscription
        }
    }
`;

export const terminateSubscriptionMutation = gql`
    ${SubscriptionFields}
    mutation TerminateSubscription($input: TerminateSubscriptionInput!) {
        terminateSubscription(input: $input) {
            ...Subscription
        }
    }
`;

export const cancelSubscriptionMutation = gql`
    ${SubscriptionFields}

    mutation CancelSubscription($input: CancelSubscriptionInput!) {
        cancelSubscription(input: $input) {
            ...Subscription
        }
    }
`;

export const reactivateSubscriptionMutation = gql`
    ${SubscriptionFields}

    mutation ReactivateSubscription($input: ReactivateSubscriptionInput!) {
        reactivateSubscription(input: $input) {
            ...Subscription
        }
    }
`;

export const updateBillingInfoMutation = gql`
    ${BillingInfoFields}

    mutation UpdateBillingInfo($input: UpdateBillingInfoInput!) {
        updateBillingInfo(input: $input) {
            ...BillingInfo
        }
    }
`;

export const listSubscriptionPlansQuery = gql`
    ${SubscriptionPlanShortFields}
    query ListPlans($activeOnly: Boolean = false) {
        listPlans(activeOnly: $activeOnly) {
            ...SubscriptionPlanShort
        }
    }
`;

export const listPackagePlansQuery = gql`
    ${PackagePlanShortFields}
    query ListPackagePlans($activeOnly: Boolean = false) {
        listPackagePlans(activeOnly: $activeOnly) {
            ...PackagePlanShort
        }
    }
`;

export const listSubscriptionsQuery = gql`
    ${SubscriptionFields}
    query ListSubscriptions($userId: ID!) {
        listSubscriptions(userId: $userId) {
            ...Subscription
        }
    }
`;

export const listPackagesQuery = gql`
    ${PackageFields}
    query ListPackages($userId: ID!) {
        listPackages(userId: $userId) {
            ...Package
        }
    }
`;

export const verifyUserMutation = gql`
    ${UserFields}

    mutation VerifyUser($input: VerifyUserInput!) {
        verifyUser(input: $input) {
            ...UserFields
        }
    }
`;

export const resetPasswordMutation = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            message
            status
        }
    }
`;

export const purchaseSubscriptionAddon = gql`
    ${PackageFields}
    mutation PurchaseSubscriptionAddon($input: PurchaseSubscriptionAddonInput!) {
        purchaseSubscriptionAddon (input: $input) {
            ...Package
        }
    }
`;

export const getUsersStatsQuery = gql`
    query GetUsersStats($input: GetUsersStatsInput!) {
        getUsersStats(input: $input) {
            active
            inactive
            total
            unverified
            verified
            registered
            subscribed
            unsubscribed
        }
    }
`;

export const getStatsQuery = gql`
    query GetStats {
        getStats {
            esim {
                availableEsims
                availablePhoneNumbers
            }
        }
    }
`;

export const listRecurlyPlansQuery = gql`
    query ListRecurlyPlans {
        listRecurlyPlans {
            id
            name
            code
        }
    }
`;

export const listRecurlyPackagesQuery = gql`
    query ListRecurlyPackages {
        listRecurlyPackages {
            id
            name
            code
        }
    }
`;

export const listEsimPlansQuery = gql`
    ${EsimPlanFields}
    query ListEsimPlans {
        listEsimPlans {
            ...EsimPlan    
        }
    }
`;

export const putSubscriptionPlan = gql`
    ${SubscriptionPlanShortFields}
    mutation PutSubscriptionPlan($input: PutSubscriptionPlanInput!) {
        putSubscriptionPlan (input: $input) {
            ...SubscriptionPlanShort
        }
    }
`;

export const updateSubscriptionPlan = gql`
    ${SubscriptionPlanShortFields}
    mutation UpdateSubscriptionPlan($input: UpdateSubscriptionPlanInput!) {
        updateSubscriptionPlan (input: $input) {
            ...SubscriptionPlanShort
        }
    }
`;

export const deleteSubscriptionPlan = gql`
    ${SubscriptionPlanShortFields}
    mutation DeleteSubscriptionPlan($id: ID!) {
        deleteSubscriptionPlan (id: $id) {
            ...SubscriptionPlanShort
        }
    }
`;

export const putPackagePlan = gql`
    ${PackagePlanShortFields}
    mutation PutPackagePlan($input: PutPackagePlanInput!) {
        putPackagePlan (input: $input) {
            ...PackagePlanShort
        }
    }
`;

export const updatePackagePlan = gql`
    ${PackagePlanShortFields}
    mutation UpdatePackagePlan($input: UpdatePackagePlanInput!) {
        updatePackagePlan (input: $input) {
            ...PackagePlanShort
        }
    }
`;

export const deletePackagePlan = gql`
    ${PackagePlanShortFields}
    mutation DeletePackagePlan($id: ID!) {
        deletePackagePlan (id: $id) {
            ...PackagePlanShort
        }
    }
`;

export const getStatsDashboardUrlQuery = gql`
    query GetStatsDashboardUrl {
        getStatsDashboardUrl
    }
`;

export const getReportStatsQuery = gql`
    query GetReportStatsQuery ($input: GetReportStatsInput) {
        getReportStats(input: $input)
    }
`;

export const getReportFilterFacetsQuery = gql`
    query GetReportFilterFacetsQuery ($input: GetReportFilterFacetsInput) {
        getReportFilterFacets(input: $input)
    }
`;

export const getReportEventsQuery = gql`
    query GetReportEventsQuery ($input: GetReportEventsInput) {
        getReportEvents(input: $input)
    }
`;
