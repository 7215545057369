import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import subscriptionEN from './locales/en/subscription.json';
import validationEN from './locales/en/validation.json';
import downloadAppPopupEN from './locales/en/downloadAppPopup.json';

import translationRU from './locales/ru/translation.json';
import subscriptionRU from './locales/ru/subscription.json';
import validationRU from './locales/ru/validation.json';
import downloadAppPopupRU from './locales/ru/downloadAppPopup.json';

const resources = {
  en: {
    translation: translationEN,
    subscription: subscriptionEN,
    validation: validationEN,
    downloadAppPopup: downloadAppPopupEN,
  },
  ru: {
    translation: translationRU,
    subscription: subscriptionRU,
    validation: validationRU,
    downloadAppPopup: downloadAppPopupRU,
  },
};

// Set language to RU if a user doesn't select different language manually
const currentLanguage = localStorage.getItem('i18nextLng');
if (!currentLanguage) {
  localStorage.setItem('i18nextLng', 'ru');
}

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: {
      order: ['localStorage'],
    },
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
