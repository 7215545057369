import React from 'react';
import { AppBar, Box, styled, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';
import Account from './Account';
import { firebaseAuth } from '../../../config';
import { useThemeTogglerProvider } from '../../../providers/ThemeSwitcherProvider';

const AppBarRoot = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  backgroundColor: theme.palette.background.default,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: 64,

  [theme.breakpoints.between('xs', 'lg')]: {
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    width: '50%',
  },

  [theme.breakpoints.up('xl')]: {
    width: '33%',
  },

  margin: 'auto',
}));

const Header = () => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const {
    t,
    i18n,
  } = useTranslation();
  const currentLanguage = localStorage.getItem('i18nextLng');

  const { toggleTheme } = useThemeTogglerProvider();

  if (loading || error) {
    return <></>;
  }

  console.log(`user here ${user?.uid}`);

  return (
    <AppBarRoot>
      <StyledToolbar>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Button
            onClick={async () => {
              toggleTheme();
            }}
          >
            {t('Toggle Theme')}
          </Button>
        </Box>
        <Box ml={2}>
          <Button
            onClick={async () => {
              const newLanguage = currentLanguage === 'en' ? 'ru' : 'en';
              await i18n.changeLanguage(newLanguage);
            }}
          >
            {t('Change Language')}
          </Button>
        </Box>
        <Box ml={2}>{user ? <Account /> : <span />}</Box>
      </StyledToolbar>
    </AppBarRoot>
  );
};

Header.defaultProps = {};

export default Header;
