import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import JWTLogin from './JWTLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  bannerChip: {
    marginRight: theme.spacing(2),
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%',
    },
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const xsWidth = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Container
        className={classes.root}
        style={{ marginTop: xsWidth ? theme.spacing(2) : theme.spacing(8) }}
        maxWidth="sm"
      >
        <Card>
          <CardContent className={classes.cardContent}>
            <Box textAlign="center">
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                {t('Sign In')}
              </Typography>
            </Box>
            <Box
              flexGrow={1}
              my={1}
            >
              <JWTLogin />
            </Box>
            <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
              <Link
                style={{ textAlign: 'start' }}
                component={RouterLink}
                to="/signup"
                variant="body1"
                color="secondary"
              >
                {t('Sign Up')}
              </Link>
              <Link
                style={{ textAlign: 'end' }}
                component={RouterLink}
                to="/forgot-password"
                variant="body1"
                color="secondary"
              >
                {t('Forgot Password')}
              </Link>
            </Box>

          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default LoginView;
