import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../config';

const GuestGuard = ({ children }) => {
  const [user, loading, error] = useAuthState(firebaseAuth);

  if (loading || error) {
    return <></>;
  }

  console.log(user);

  if (user) {
    return <Redirect to="/admin/users" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
