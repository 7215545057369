import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { firebaseAuth } from '../../../config';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 48,
    width: 48,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await firebaseAuth.signOut();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('Failed'), {
        variant: 'warning',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        /* @ts-ignore */
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
        />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          to="/admin-profile"
        >
          {t('Profile')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {t('Logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
