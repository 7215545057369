import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache/inmemory/inMemoryCache';
import firebase from 'firebase';

const url = process.env.REACT_APP_API_URL;
const region = 'us-east-1';
const inMemoryCache = new InMemoryCache({
  typePolicies: {
    AdminQuery: {
      fields: {
        getUser: {
          read(_, {
            args,
            toReference,
          }) {
            console.log('getUser');
            return toReference({
              __typename: 'User',
              id: args?.id,
            });
          },
        },
      },
    },
  },
});

const publicLink = ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
    },
  }),
  createSubscriptionHandshakeLink(url, createHttpLink({ uri: url })),
]);

const getProtectedLink = (token: string) => ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: {
      type: AUTH_TYPE.AWS_LAMBDA,
      token: `Bearer ${token}`,
    },
  }),
  createSubscriptionHandshakeLink(url, createHttpLink({ uri: url })),
]);

const getAdminLink = (token: string) => ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: token,
    },
  }),
  createSubscriptionHandshakeLink(url, createHttpLink({ uri: url })),
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPublicApolloClient = (): ApolloClient<any> => new ApolloClient({
  link: publicLink,
  cache: inMemoryCache,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProtectedApolloClient = (token: string): ApolloClient<any> => new ApolloClient({
  link: getProtectedLink(token),
  cache: inMemoryCache,
});

let adminApolloClient: ApolloClient<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAdminApolloClient = async (): Promise<ApolloClient<any>> => {
  if (!adminApolloClient) {
    const token = await firebase.auth().currentUser!.getIdToken();
    console.log('creating new ApolloClient');
    adminApolloClient = new ApolloClient({
      link: getAdminLink(token),
      cache: inMemoryCache,
    });
  }

  return adminApolloClient;
};
