import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { RecurlyProvider } from '@recurly/react-recurly';
import { BrowserRouter } from 'react-router-dom';
import ModalProvider from 'mui-modal-provider';
import ScrollReset from './components/ScrollReset';
import routes, { renderRoutes } from './routes';
import config from './config';
// eslint-disable-next-line import/no-cycle
import ErrorBoundary from './components/ErrorBoundary';
import Content from './Content';
import AppsyncProvider from './providers/AppsyncProvider';
import { ThemeSwitcherProvider } from './providers/ThemeSwitcherProvider';

const App = () => (
  <BrowserRouter>
    <ErrorBoundary>
      {/* @ts-ignore */}
      <ThemeSwitcherProvider>
        <ModalProvider>
          <CssBaseline />
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <HelmetProvider>
              <AppsyncProvider>
                <RecurlyProvider publicKey={config.recurlyPublicKey}>
                  <ScrollReset />
                  <Content>
                    {renderRoutes(routes)}
                  </Content>
                </RecurlyProvider>
              </AppsyncProvider>
            </HelmetProvider>
          </SnackbarProvider>
        </ModalProvider>
      </ThemeSwitcherProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

export default App;
