import React from 'react';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import App from '../App';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/error-page');
    // TODO: send error to the Backend
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <App />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
